import moment from 'moment'
import 'moment/dist/locale/fr-ch'
import 'moment/dist/locale/nl-be'
import 'moment/dist/locale/nl'
import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/ro'
import i18n from '@src/i18n'

export default {
  getRelativeDateString(period, locale) {
    if (locale === undefined) {
      locale = this._getCurrentLocaleForMomentJs()
    }
    if (period === 'today') {
      return moment().locale(locale).format('LL')
    } else if (period === 'tomorrow') {
      return moment().locale(locale).add(1, 'day').format('LL')
    } else if (period === 'yesterday') {
      return moment().locale(locale).add(-1, 'day').format('LL')
    } else if (period === 'these_3_days') {
      return `${moment().locale(locale).format('LL')} - ${moment()
        .locale(locale)
        .add(2, 'day')
        .format('LL')}`
    } else if (period === 'next_3_days') {
      return `${moment().add(1, 'day').locale(locale).format('LL')} - ${moment(moment().add(1, 'day'))
        .add(2, 'days')
        .locale(locale)
        .format('LL')}`;
    } else if (period === 'these_5_days') {
      return `${moment().locale(locale).format('LL')} - ${moment()
        .locale(locale)
        .add(4, 'day')
        .format('LL')}`
    } else if (period === 'next_5_days') {
      return `${moment().add(1, 'day').locale(locale).format('LL')} - ${moment(moment().add(1, 'day'))
        .add(4, 'days')
        .locale(locale)
        .format('LL')}`
    } else if (period === 'these_7_days') {
      return `${moment().locale(locale).format('LL')} - ${moment()
        .locale(locale)
        .add(6, 'day')
        .format('LL')}`
    } else if (period === 'next_7_days') {
      return `${moment().add(1, 'day').locale(locale).format('LL')} - ${moment(moment().add(1, 'day'))
        .add(6, 'days')
        .locale(locale)
        .format('LL')}`
    } else if (period === 'last_3_days') {
      return `${moment()
        .locale(locale)
        .add(-2, 'day')
        .format('LL')} - ${moment().locale(locale).format('LL')}`
    } else if (period === 'last_5_days') {
      return `${moment()
        .locale(locale)
        .add(-4, 'day')
        .format('LL')} - ${moment().locale(locale).format('LL')}`
    } else if (period === 'this_week') {
      return `${moment()
        .locale(locale)
        .startOf('week')
        .format('LL')} - ${moment().locale(locale).endOf('week').format('LL')}`
    } else if (period === 'last_week') {
      return `${moment()
        .startOf('week')
        .add(-1, 'week')
        .locale(locale)
        .format('LL')} - ${moment()
        .locale(locale)
        .endOf('week')
        .add(-1, 'week')
        .format('LL')}`
    } else if (period === 'next_week') {
      return `${moment()
        .startOf('week')
        .add(1, 'week')
        .locale(locale)
        .format('LL')} - ${moment()
        .locale(locale)
        .endOf('week')
        .add(1, 'week')
        .format('LL')}`
    } else if (period === 'last_7_days') {
      return `${moment()
        .locale(locale)
        .add(-6, 'day')
        .format('LL')} - ${moment().locale(locale).format('LL')}`
    } else if (period === 'this_month') {
      return `${moment()
        .locale(locale)
        .startOf('month')
        .format('LL')} - ${moment().locale(locale).endOf('month').format('LL')}`
    } else if (period === 'last_month') {
      return `${moment()
        .add(-1, 'month')
        .startOf('month')
        .locale(locale)
        .format('LL')} - ${moment()
        .locale(locale)
        .add(-1, 'month')
        .endOf('month')
        .format('LL')}`
    } else if (period === 'next_month') {
      return `${moment()
        .add(1, 'month')
        .startOf('month')
        .locale(locale)
        .format('LL')} - ${moment()
        .locale(locale)
        .add(1, 'month')
        .endOf('month')
        .format('LL')}`
    } else if (period === 'last_30_days') {
      return `${moment()
        .locale(locale)
        .add(-29, 'day')
        .format('LL')} - ${moment().locale(locale).format('LL')}`
    } else if (period === 'these_30_days') {
      return `${moment().locale(locale).format('LL')} - ${moment()
        .locale(locale)
        .add(29, 'day')
        .format('LL')}`
    } else if (period === 'next_30_days') {
      return `${moment().add(1, 'day').locale(locale).format('LL')} - ${moment(moment().add(1, 'day'))
        .add(29, 'days')
        .locale(locale)
        .format('LL')}`
    } else if (period === 'this_quarter') {
      return `${moment()
        .locale(locale)
        .startOf('quarter')
        .format('LL')} - ${moment().locale(locale).endOf('quarter').format('LL')}`
    } else if (period === 'last_quarter') {
      return `${moment()
        .add(-1, 'quarter')
        .startOf('quarter')
        .locale(locale)
        .format('LL')} - ${moment()
        .locale(locale)
        .add(-1, 'quarter')
        .endOf('quarter')
        .format('LL')}`
    } else if (period === 'next_quarter') {
      return `${moment()
        .add(1, 'quarter')
        .startOf('quarter')
        .locale(locale)
        .format('LL')} - ${moment()
        .locale(locale)
        .add(1, 'quarter')
        .endOf('quarter')
        .format('LL')}`
    } else if (period === 'last_90_days') {
      return `${moment()
        .locale(locale)
        .add(-89, 'day')
        .format('LL')} - ${moment().locale(locale).format('LL')}`
    } else if (period === 'these_90_days') {
      return `${moment().locale(locale).format('LL')} - ${moment()
        .locale(locale)
        .add(89, 'day')
        .format('LL')}`
    } else if (period === 'next_90_days') {
      `${moment().add(1, 'day').locale(locale).format('LL')} - ${moment(moment().add(1, 'day'))
        .add(89, 'days')
        .locale(locale)
        .format('LL')}`
    } else if (period === 'this_year') {
      return `${moment()
        .locale(locale)
        .startOf('year')
        .format('LL')} - ${moment().locale(locale).endOf('year').format('LL')}`
    } else if (period === 'last_year') {
      return `${moment()
        .add(-1, 'year')
        .startOf('year')
        .locale(locale)
        .format('LL')} - ${moment()
        .locale(locale)
        .add(-1, 'year')
        .endOf('year')
        .format('LL')}`
    } else if (period === 'next_year') {
      return `${moment()
        .add(1, 'year')
        .startOf('year')
        .format('LL')} - ${moment()
        .locale(locale)
        .add(1, 'year')
        .endOf('year')
        .format('LL')}`
    } else if (period === 'last_365_days') {
      return `${moment()
        .locale(locale)
        .add(-364, 'day')
        .format('LL')} - ${moment().locale(locale).format('LL')}`
    } else if (period === 'these_365_days') {
      return `${moment().locale(locale).format('LL')} - ${moment()
        .add(364, 'day')
        .locale(locale)
        .format('LL')}`
    } else if (period === 'next_365_days') {
      `${moment().add(1, 'day').locale(locale).format('LL')} - ${moment(moment().add(1, 'day'))
        .add(364, 'days')
        .locale(locale)
        .format('LL')}`
    }
  },
  _getCurrentLocaleForMomentJs() {
    switch (i18n.vueI18n.global.locale) {
      case 'fr_BE':
      case 'fr_FR':
        return 'fr'
      case 'fr_CH':
        return 'fr-ch'
      case 'nl_BE':
        return 'nl-be'
      case 'nl_NL':
        return 'nl'
      default:
        return 'en'
    }
  },
  formatDate(date, format, locale) {
    locale = locale !== undefined ? locale : 'fr'
    let _moment = moment(date)
    return _moment.isValid() ? _moment.locale(locale).format(format) : ''
  },
  momentLocalised(date, format, locale) {
    if (locale === undefined) {
      locale = this._getCurrentLocaleForMomentJs()
    }
    return moment(date).locale(locale).format(format)
  }

}
